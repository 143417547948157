.section {
  @apply pt-4 pb-4;
}

@screen md {
  .section {
    @apply pt-8 pb-8;
  }
}

.section-heading {
  @apply text-xl md:text-4xl font-semibold text-gray-800 py-4 md:py-8

  /*&:after {
    position: absolute;
    left: 0;
    bottom: 16px;
    content: "";
    width: 32px;
    height: 1px;
    border-bottom: 2px solid #212121;
    @include media-breakpoint-down(sm) {
      bottom: 20px;
      border-bottom: 1.5px solid #212121;
    }
  }*/
}

.horizontal {
  box-sizing: border-box;
  background: transparent;
  box-shadow: none;
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .horizontal {
    padding-left: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    align-items: stretch;
    flex-flow: row nowrap !important;
  }
}
  

.page-enter {
  opacity: 0.01;
}

.page-enter.page-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.page-leave {
  opacity: 1;
}

.page-appear {
  opacity: 0.01;
  z-index: 1;
}

.page-appear.page-appear-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.page-leave.page-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

@keyframes fadeInImg {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

.img-loading {
  opacity: 0;
  width: 100%;
  height: auto;
}

.img-loaded {
  animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
  position: relative;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-duration: 0.7s;
  animation-delay: 0.1s;
}

