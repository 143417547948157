/* latin-ext */
@font-face {
  font-family: '__Poppins_b2c03c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fe0777f1195381cb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_b2c03c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/eafabf029ad39a43-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_b2c03c';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f10b8e9d91f3edcb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_b2c03c';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/8888a3826f4a3af4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_b2c03c';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c3bc380753a8436c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_b2c03c';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0484562807a97172-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_b2c03c';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a1386beebedccca4-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_b2c03c';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b957ea75a84b6ea7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_b2c03c';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/b8442747db2a9bad-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_b2c03c';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/6245472ced48d3be-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_b2c03c';src: local("Arial");ascent-override: 93.62%;descent-override: 31.21%;line-gap-override: 8.92%;size-adjust: 112.16%
}.__className_b2c03c {font-family: '__Poppins_b2c03c', '__Poppins_Fallback_b2c03c';font-style: normal
}

/* Slider */
.slick-disabled {
    display: none !important;
}
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.pcslick .slick-list {
    margin: 0 -10px;
}
.pcslick .slick-slide > div {
    padding: 0 5px;
}
.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}
.slick-slider .slick-track, .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}
.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
}
.slick-track:before, .slick-track:after {
    display: table;
    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
/* Arrows */
.slick-prev, .slick-next {
    font-size: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 40px;
    height: 40px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    outline: none;
    text-align: center !important;
    line-height: 1 !important;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px 1px !important;
    border-radius: 50% !important;
    border-width: 2px !important;
    border-style: solid !important;
    border-color: transparent !important;
    background: #fff !important;
    z-index: 2;
}
.slick-prev svg, .slick-next svg {
    font-size: 20px;
    color: #343a47;
    display: inline-block !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
    opacity: 1;
}
.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
    opacity: 0.25;
}
.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
    left: -25px;
}
[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}
[dir='rtl'] .slick-prev:before {
    content: 'â†’';
}
.slick-next {
    right: -25px;
}
[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}
[dir='rtl'] .slick-next:before {
    content: 'â†';
}
/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}
.slick-dots {
    position: relative;
    margin: 10px 0 0 0;
    display: block;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover, .slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li button:before {
    content: "";
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #1db954;
    text-align: center;
    opacity: 0.25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
li.slick-active {
    opacity: 1;
}
.slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: black;
}
.slick-slide .drop-shadow {
    box-shadow: none;
    transition: box-shadow 0.1s ease-in-out;
}
.slick-slide.slick-active .drop-shadow {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (min-width: 768px) {
  .section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.section-heading {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

@media (min-width: 768px) {

  .section-heading {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.horizontal {
  box-sizing: border-box;
  background: transparent;
  box-shadow: none;
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .horizontal {
    padding-left: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    align-items: stretch;
    flex-flow: row nowrap !important;
  }
}
  

.page-enter {
  opacity: 0.01;
}

.page-enter.page-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.page-leave {
  opacity: 1;
}

.page-appear {
  opacity: 0.01;
  z-index: 1;
}

.page-appear.page-appear-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.page-leave.page-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

@-webkit-keyframes fadeInImg {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes fadeInImg {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

.img-loading {
  opacity: 0;
  width: 100%;
  height: auto;
}

.img-loaded {
  -webkit-animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
          animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
  position: relative;
  opacity: 0;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 0.7s;
          animation-duration: 0.7s;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}


